$(document).ready(function() {
	if(!document.querySelector('.C0078')) return false;
	
	var component78 = {
		$el : $('.C0078'),
		init : function() {
			this.$el.find('.carousel-wrap').each(function() {
				if($(this).find('.carousel-box').length>1) {
					component78.runSlick($(this));
				} else {
					$(this).addClass('active');
				}
			});
		},
		runSlick: function($obj) {
			var fontColor = 'black';
			if($obj.find('.carousel-box').eq(0).find('.text-area').hasClass('text-white')) {
				fontColor = 'white';
			}
			$obj.on('init', function(slick){
				var $this = $(this);
				$this.find('.slick-slide').attr('role', 'tabpanel');
			}).slick({
				infinite: false, //LGEGMO-5525
				listStyle: true, //LGEGMO-5525
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : true,
				dots: true,
				//adaptiveHeight: true,
				// lazyLoad: 'ondemand',
				autoplay: true,
				autoplaySpeed: 8000,
				prevArrow: carouselOptions.bigAnglePrev, // common.js variable
				nextArrow: carouselOptions.bigAngleNext, // common.js variable
				//appendDots:$obj.find('.slick-indicator .slick-dot-wrap')
				appendDots:$obj.next().find('.slick-dot-wrap')
			});
			$obj.addClass('active');

			$obj.addClass('slick-'+fontColor);
			$obj.on('beforeChange', function(event, slick, currentSlide, nextSlide){
				$obj.find('.carousel-box[data-slick-index='+nextSlide+'] .visual-area img[data-src]').addClass('lazyload');
			});
			$obj.on('afterChange', function(event, slick, currentSlide){
				if($obj.find('.slick-current .text-area').hasClass('text-white')) {
					fontColor = 'white';
				} else {
					fontColor = 'black';
				}
				$obj.removeClass('slick-white').removeClass('slick-black').addClass('slick-'+fontColor);
			});

			$obj.next().find('.slide-pause').addClass('active').on('click',function(e){
				e.preventDefault();
				if ($(this).hasClass('pause')) {
					$(this).removeClass('pause').addClass('play');
					$(this).text($(this).attr('data-title-play'));
					$(this).attr('aria-label',$(this).attr('data-title-play'));
					$obj.slick('slickPause');
				} else {
					$(this).removeClass('play').addClass('pause');
					$(this).text($(this).attr('data-title-stop'));
					$(this).attr('aria-label',$(this).attr('data-title-stop'));
					$obj.slick('slickPlay');
				}
			});
			// $obj.find('.slide-pause').trigger('click');
		}
	};
	component78.init();

});